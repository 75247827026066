<script lang="ts">
  import { CheckCircle, HelpCircle, Info, XCircle } from "lucide-svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher<{ action: void }>();

  /** The kind of toast to display. */
  export let kind: "info" | "success" | "error" = "info";

  /** The message to display inside the toast. */
  export let message: string;

  /** An optional action to provide as a button on the toast. */
  export let action: string = "";
</script>

<div class="toast-box">
  {#if kind === "info"}
    <Info size={20} class="text-primary flex-shrink-0" />
  {:else if kind === "success"}
    <CheckCircle size={20} class="text-green-300 flex-shrink-0" />
  {:else if kind === "error"}
    <XCircle size={20} class="text-red-300 flex-shrink-0" />
  {:else}
    <HelpCircle size={20} class="text-primary flex-shrink-0" />
  {/if}

  <p class="ml-3">
    {message}
  </p>

  {#if action}
    <div class="ml-auto">
      <button
        class="h-5 ml-3 px-2 flex items-center text-xs border rounded-md border-zinc-400 hover:border-zinc-200 hover:text-white transition-colors"
        on:click={() => dispatch("action")}
      >
        {action}
      </button>
    </div>
  {/if}
</div>

<style lang="postcss">
  .toast-box {
    @apply border border-zinc-700 bg-zinc-900/80 backdrop-blur-sm;
    @apply p-4 rounded-lg flex items-start pointer-events-auto;
    @apply text-sm;
  }
</style>
