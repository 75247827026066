<script lang="ts">
  import { Portal } from "@rgossiaux/svelte-headlessui";
  import { onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { fly } from "svelte/transition";

  import { toastStore } from "$lib/toast";
  import Toast from "./Toast.svelte";

  onMount(() => {
    // Remove old toasts periodically.
    const id = setInterval(() => {
      const now = Date.now();
      toastStore.update(($toasts) => $toasts.filter((t) => t.expires > now));
    }, 250);
    return () => clearInterval(id);
  });
</script>

<Portal>
  <div
    class="fixed inset-0 z-20 pointer-events-none flex justify-end px-6 py-14"
  >
    <div class="w-full max-w-md">
      {#each $toastStore.slice().reverse() as toast (toast)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class="mb-2"
          on:click={() =>
            ($toastStore = $toastStore.filter((t) => t !== toast))}
          animate:flip={{ duration: 500 }}
          transition:fly={{ x: 360, duration: 500 }}
        >
          <Toast
            kind={toast.kind}
            message={toast.message}
            action={toast.action}
            on:action={toast.onAction ?? (() => {})}
          />
        </div>
      {/each}
    </div>
  </div>
</Portal>
