<!-- @component Adds Open Graph <meta> tags to page. -->
<script lang="ts">
  // The fallback og:title and og:description values are intentionally kept
  // short to avoid being repetitive when linking dashboard pages.
  export let title: string = "Modal";
  export let description: string =
    "The serverless platform for AI and data teams.";
  export let imageUrl: string = "https://modal.com/assets/social-image.jpg";
</script>

<svelte:head>
  <!-- HTML Meta tags -->
  <meta name="description" content={description} />

  <!-- Opengraph -->
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Modal" />
  <meta property="og:title" content={title} />
  <meta property="og:description" content={description} />
  <meta property="og:image" content={imageUrl} />

  <!-- Twitter -->
  <meta name="twitter:card" content="summary_large_image" />
</svelte:head>
