<!-- @component Tracks page views and events, integrating with GA, Sentry, and Segment. -->

<script lang="ts">
  import { afterNavigate } from "$app/navigation";

  import { onMount } from "svelte";

  import { analytics } from "$lib/analytics";

  // Google Analytics
  // eslint-disable-next-line
  function gtag(..._: any[]) {
    const w = window as any;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(arguments);
  }
  onMount(() => {
    gtag("js", new Date());
    gtag("config", "G-GRVB0ZLLD8");

    // Track UTM tags
    const urlParams = new URLSearchParams(location.search);
    const utmSource = urlParams.get("utm_source");
    if (utmSource) {
      localStorage.setItem("utm-source", utmSource);
    }
  });
  afterNavigate(({ to }) => {
    gtag("set", "page_path", to?.url?.pathname);
    gtag("event", "page_view");
  });

  // Segment
  onMount(() => {
    analytics.load({
      writeKey: "adVkYFLBEV3XP82TZeK7mTcJKBLfbUIH",
    });
  });
  afterNavigate(() => {
    analytics.page();
  });
</script>

<!-- Global site tag (gtag.js) - Google Analytics -->
<svelte:head>
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-GRVB0ZLLD8"
  ></script>
</svelte:head>
